export const ADD_SUB_WITHIN_TEN = 'add_sub_within_ten';
export const ADD_WITHIN_TEN = 'add_within_ten';
export const ADD_WITHIN_TEN_FILL = 'add_within_ten_fill';
export const SUB_WITHIN_TEN = 'sub_within_ten';
export const SUB_WITHIN_TEN_FILL = 'sub_within_ten_fill';
export const ADD_SUB_WITHIN_TWENTY = 'add_sub_within_twenty';
export const ADD_LOOP_WITHIN_TWENTY = 'add_loop_within_twenty';
export const ADD_WITHIN_TWENTY = 'add_within_twenty';
export const ADD_WITHIN_TWENTY_FILL = 'add_within_twenty_fill';
export const SUB_WITHIN_TWENTY = 'sub_within_twenty';
export const SUB_WITHIN_TWENTY_FILL = 'sub_within_twenty_fill';
export const ADD_SUB_WITHIN_TWENTY_WITH_OPERANDS_IN_TEN = 'add_sub_within_twenty_with_operands_in_ten';
export const ADD_WITHIN_TWENTY_WITH_OPERANDS_IN_TEN = 'add_within_twenty_with_operands_in_ten';